// @flow

import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import * as React from 'react';
import styled from 'styled-components';
import {
  Layout,
  MarkdownPs,
  Section,
  Spacer,
  TextHeading,
} from '../components';
import { Dimen } from '../constants';

import type { PageTermsQuery } from '../__generated__/PageTermsQuery';

type Props = $ReadOnly<{|
  data: PageTermsQuery,
|}>;

const PageTerms = ({ data: { page } }: Props) => {
  if (!page) throw new Error('Privacy missing from CMS');

  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Section>
        <StyledTextHeading level={1} tag="h1">
          {page.title}
        </StyledTextHeading>
        {page.content && <MarkdownPs html={page.content} />}
        <Spacer h={Dimen.spacing * 2} />
      </Section>
    </Layout>
  );
};
export default PageTerms;

export const query = graphql`
  query PageTermsQuery {
    page: datoCmsPageTermsOfService {
      title
      content
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;

const StyledTextHeading = styled(TextHeading)`
  text-align: center;
`;
